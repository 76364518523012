
import './app-info.css';

const AppInfo = () => {
  return (
    <div className="app-info">
      <h1 className="app-info-title">Movie Catalog Application</h1>
    </div>
  );
};

export default AppInfo;
